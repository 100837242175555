import { createApp } from 'vue'
import ArcoVue from '@arco-design/web-vue'
import App from './App.vue'
import router from './router'
import '@arco-themes/vue-ads1-3/index.less'
import { createPinia } from 'pinia'
// import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
// import '@/utils/operateTimeout.js'
// i18.locale.value = 'en-US'
const app = createApp(App)
const pinia = createPinia()
app.use(router)
app.use(ArcoVue)
app.use(pinia)
// app.use(i18)
app.mount('#app')
