import { createRouter, createWebHashHistory } from 'vue-router'
import local from '@/utils/local.js'
const routes = [
  {
    path: '/',
    component: () => import('../views/home/index.vue'),
    redirect: '/homepage',
    children: [
      {
        path: '/homepage',
        component: () => import('../views/home/Homepage.vue')
      },
      {
        path: '/about',
        component: () => import('../views/home/about/index.vue'),
        children: [
          {
            path: 'aboutus',
            component: () => import('../views/home/about/AboutUs.vue')
          },
          {
            path: 'warehousing',
            component: () => import('../views/home/about/LogisticsWarehousing.vue')
          },
          {
            path: 'vehicles',
            component: () => import('../views/home/about/Vehicles.vue')
          },
          {
            path: 'servicearea',
            component: () => import('../views/home/about/ServiceArea.vue')
          }
        ]
      },
      {
        path: '/support',
        component: () => import('../views/home/support/index.vue'),
        children: [
          {
            path: 'buyerfaq',
            component: () => import('../views/home/support/BuyFaqs.vue')
          },
          {
            path: 'retailerfaq',
            component: () => import('../views/home/support/RetailerFaq.vue')
          }
        ]
      },
      {
        path: '/contact',
        component: () => import('../views/home/Contact.vue')
      },
      {
        path: '/resources',
        component: () => import('../views/home/resources/index.vue'),
        children: [
          {
            path: 'privacypolicy',
            component: () => import('../views/home/resources/PrivacyPolicy.vue')
          },
          {
            path: 'termsconditions',
            component: () => import('../views/home/resources/TermsConditions.vue')
          },
          {
            path: 'feessurcharge',
            component: () => import('../views/home/resources/FeesSurcharge.vue')
          },
          {
            path: 'tailoredsolutions',
            component: () => import('../views/home/resources/TailoredDeliverySolutions.vue')
          },
          {
            path: 'flexibleoptions',
            component: () => import('../views/home/resources/FlexibleDeliveryOptions.vue')
          },
          {
            path: 'deliverysolutions',
            component: () => import('../views/home/resources/LastMileDeliverySolutions.vue')
          },
          {
            path: 'applicationsolution',
            component: () => import('../views/home/resources/ApplicationSolution.vue')
          },
          {
            path: 'orderassignment',
            component: () => import('../views/home/resources/RealTimeOrderAssignment.vue')
          },
          {
            path: 'sustainableoptions',
            component: () => import('../views/home/resources/SustainableDeliveryOptions.vue')
          }
          // {
          //   path: 'blog',
          //   component: () => import('../views/home/resources/Blog.vue')
          // },
          // {
          //   path: 'blogdetail',
          //   component: () => import('../views/home/resources/BlogDetail.vue')
          // }
        ]
      },
      {
        path: '/knowledge',
        component: () => import('../views/home/knowledge/index.vue'),
        children: [
          {
            path: 'classify',
            component: () => import('../views/home/knowledge/Classify.vue')
          },
          {
            path: 'blog',
            component: () => import('../views/home/knowledge/Blog.vue')
          },
          {
            path: 'blogdetail',
            component: () => import('../views/home/knowledge/BlogDetail.vue')
          }
        ]
      },
      {
        path: '/driver',
        component: () => import('../views/home/driver/index.vue'),
        children: [
          {
            path: 'qualifications',
            component: () => import('../views/home/driver/Qualifications.vue')
          },
          {
            path: 'driverfaq',
            component: () => import('../views/home/driver/Faq.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    component: () => import('../views/account/Login.vue')
  },
  {
    path: '/resetpwd',
    component: () => import('../views/account/Resetpwd.vue')
  },
  {
    path: '/register',
    component: () => import('../views/account/Register.vue')
  },
  {
    path: '/successreset',
    component: () => import('../views/account/Resetsuccess.vue')
  },
  {
    path: '/test',
    component: () => import('../views/home/Test.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../components/Home.vue'),
    // redirect: '/workcenter',
    children: [
      // workbeach
      {
        path: '/workcenter',
        meta: {
          breadcrumb: ['Work Bench']
        },
        component: () => import('../views/workbeach/workcenter.vue')
      },
      // Account
      {
        path: '/usercenter',
        component: () => import('../views/usercenter/index.vue'),
        children: [
          {
            path: 'myprofile',
            meta: {
              breadcrumb: ['Account', 'My Profile']
            },
            component: () => import('../views/usercenter/Myprofile.vue')
          },
          {
            path: 'displayoptions',
            meta: {
              breadcrumb: ['Account', 'Preference']
            },
            component: () => import('../views/usercenter/Displayoption.vue')
          },
          {
            path: 'wallet',
            meta: {
              breadcrumb: ['Account', 'Wallet']
            },
            component: () => import('../views/usercenter/Wallet.vue')
          },
          {
            path: 'recharge',
            meta: {
              breadcrumb: ['Account', 'Wallet', 'Add Funds']
            },
            component: () => import('../views/usercenter/Recharge.vue')
          },
          {
            path: 'rechargesuccess',
            meta: {
              breadcrumb: ['Account', 'Wallet', 'Add Funds']
            },
            component: () => import('../views/usercenter/Rechargesuccess.vue')
          },
          {
            path: 'transaction',
            meta: {
              breadcrumb: ['Account', 'Wallet', 'Transaction']
            },
            component: () => import('../views/usercenter/Transaction.vue')
          },
          {
            path: 'statement',
            meta: {
              breadcrumb: ['Account', 'Wallet', 'Statement']
            },
            component: () => import('../views/usercenter/Statement.vue')
          },
          {
            path: 'statementdetail',
            meta: {
              breadcrumb: ['Account', 'Wallet', 'Statement']
            },
            component: () => import('../views/usercenter/Statementdetail.vue')
          },
          {
            path: 'adjustbill',
            meta: {
              breadcrumb: ['Account', 'Wallet', 'Statement']
            },
            component: () => import('../views/usercenter/Adjustbill.vue')
          },
          {
            path: 'claim',
            meta: {
              breadcrumb: ['Account', 'Wallet', 'Statement']
            },
            component: () => import('../views/usercenter/Claim.vue')
          },
          {
            path: 'claimedit',
            meta: {
              breadcrumb: ['Account', 'Wallet', 'Statement']
            },
            component: () => import('../views/usercenter/Claimedit.vue')
          },
          {
            path: 'claimlist',
            meta: {
              breadcrumb: ['Account', 'Wallet', 'Statement']
            },
            component: () => import('../views/usercenter/Claimlist.vue')
          },
          {
            path: 'statistics',
            meta: {
              breadcrumb: ['Account', 'Statistics']
            },
            component: () => import('../views/usercenter/Statistics.vue')
          }
        ]
      },
      // order
      {
        path: '/order',
        component: () => import('../views/order/index.vue'),
        // redirect: '/order/add/addneworder',
        children: [
          // edit
          {
            path: 'editorder',
            meta: {
              breadcrumb: ['Order', 'Edit']
            },
            component: () => import('../views/order/add/editOrder.vue')
          },
          // add
          {
            path: 'add',
            component: () => import('../views/order/add/index.vue'),
            redirect: '/order/add/addneworder',
            children: [
              {
                path: 'addneworder',
                meta: {
                  breadcrumb: ['Order', 'Add New']
                },
                component: () => import('../views/order/add/Addneworder.vue')
              }
            ]
          },
          // import
          {
            path: 'import',
            component: () => import('../views/order/import/index.vue'),
            redirect: '/order/import/importorders',
            children: [
              {
                path: 'importorders',
                meta: {
                  breadcrumb: ['Order', 'Import Order']
                },
                component: () => import('../views/order/import/Importorders.vue')
              },
              {
                path: 'importordersdetail',
                meta: {
                  breadcrumb: ['Order', 'Import Order Detail']
                },
                component: () => import('../views/order/import/Importdetail.vue')
              }
            ]
          },
          // orderlist
          {
            path: 'list',
            component: () => import('../views/order/list/index.vue'),
            children: [
              {
                path: 'orderlist',
                component: () => import('../views/order/list/Orderlist.vue'),
                meta: {
                  breadcrumb: ['Order', 'Order List']
                }
              },
              {
                path: 'listdetail',
                meta: {
                  breadcrumb: ['Order', 'Detail']
                },
                component: () => import('../views/order/list/Listdetail.vue')
              }
            ]
          },
          // ltl
          {
            path: 'ltl',
            component: () => import('../views/order/ltl/index.vue'),
            redirect: '/order/ltl/addltl',
            children: [
              {
                path: 'addltl',
                meta: {
                  breadcrumb: ['Order', 'Add LTL']
                },
                component: () => import('../views/order/ltl/AddLTL.vue')
              },
              {
                path: 'listltl',
                meta: {
                  breadcrumb: ['Order', 'LTL List']
                },
                component: () => import('../views/order/ltl/ListLTL.vue')
              },
              {
                path: 'ltldetail',
                meta: {
                  breadcrumb: ['Order', 'Ltl Detail']
                },
                component: () => import('../views/order/ltl/DetailLTL.vue')
              },
              {
                path: 'editltl',
                component: () => import('../views/order/ltl/EditLTL.vue')
              }
            ]
          },
          // pick up
          {
            path: 'pickup',
            component: () => import('../views/order/pickup/index.vue'),
            redirect: '/order/pickup/addpickup',
            children: [
              {
                path: 'addpickup',
                meta: {
                  breadcrumb: ['Order', 'Add Pick Up']
                },
                component: () => import('../views/order/pickup/Addpickup.vue')
              },
              {
                path: 'pickuplist',
                meta: {
                  breadcrumb: ['Order', 'Pick Up List']
                },
                component: () => import('../views/order/pickup/Pickuplist.vue')
              }
            ]
          }
        ]
      },
      // shipment
      {
        path: '/shipment',
        meta: {
          breadcrumb: ['Shipment']
        },
        component: () => import('../views/shipment/Shipment.vue')
      },
      // products
      {
        path: '/products',
        component: () => import('../views/products/index.vue'),
        children: [
          {
            path: 'addproducts',
            meta: {
              breadcrumb: ['Products', 'Add New']
            },
            component: () => import('../views/products/Addproduct.vue')
          },
          {
            path: 'editproducts',
            meta: {
              breadcrumb: ['Products', 'Edit']
            },
            component: () => import('../views/products/ProductEdit.vue')
          },
          {
            path: 'productslist',
            meta: {
              breadcrumb: ['Products', 'Products List']
            },
            component: () => import('../views/products/Productlist.vue')
          },
          {
            path: 'importpro',
            component: () => import('../views/products/import/index.vue'),
            redirect: '/products/importpro/importproducts',
            children: [
              {
                path: 'importproducts',
                meta: {
                  breadcrumb: ['Products', 'Import Products']
                },
                component: () => import('../views/products/import/Importproduct.vue')
              },
              {
                path: 'importdetail',
                meta: {
                  breadcrumb: ['Products', 'Products Detail']
                },
                component: () => import('../views/products/import/ImportDdetail.vue')
              }
            ]
          }
        ]
      },
      // Address
      {
        path: '/address',
        component: () => import('../views/address/index.vue'),
        redirect: '/address/addresslist',
        children: [
          {
            path: 'addaddress',
            meta: {
              breadcrumb: ['Address', 'Add New']
            },
            component: () => import('../views/address/Addaddress.vue')
          },
          {
            path: 'editaddress',
            meta: {
              breadcrumb: ['Address', 'Edit']
            },
            component: () => import('../views/address/AddressEdit.vue')
          },
          {
            path: 'addresslist',
            meta: {
              breadcrumb: ['Address', 'Address List']
            },
            component: () => import('../views/address/Addresslist.vue')
          }
        ]
      },
      // statistics
      {
        path: '/statistics',
        meta: {
          breadcrumb: ['Statistics']
        },
        component: () => import('../views/statistics/Statistics.vue')
      },
      // help
      {
        path: '/help',
        component: () => import('../views/help/index.vue'),
        children: [
          {
            path: 'faq',
            meta: {
              breadcrumb: ['Help', 'F.A.Q']
            },
            component: () => import('../views/help/FAQ.vue')
          },
          {
            path: 'surcharge',
            meta: {
              breadcrumb: ['Help', 'Surcharge']
            },
            component: () => import('../views/help/Surcharge.vue')
          },
          {
            path: 'teachsupport',
            meta: {
              breadcrumb: ['Help', 'Teach Support']
            },
            component: () => import('../views/help/Teachsupport.vue')
          },
          {
            path: 'contact',
            meta: {
              breadcrumb: ['Help', 'Teach Support']
            },
            component: () => import('../views/help/Contact.vue')
          }
        ]
      },
      // 网络错误
      {
        path: '/networkerr',
        component: () => import('../components/NetworkError.vue')
      }
    ]
  },
  // 网络错误
  {
    path: '/networkerr',
    component: () => import('../components/NetworkError.vue')
  },
  // 错误
  {
    path: '/404',
    component: () =>
      import('../components/Error.vue')
  },
  {
    path: '/:patchMatch(.*)*',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// 导航拦截
router.beforeEach((to, from, next) => {
  // window.scrollTo(0, 0)
  // 判断是否登录
  const token = local.get('token')
  // const token = sessionStorage.getItem('token')
  if (token) {
    const logindirectly = JSON.parse(local.get('userInfo')).login_directly_to
    if (to.path === '/login' || to.path === '/homepage') {
      next({ path: logindirectly })
    } else {
      next()
    }
  }
  if (!token) {
    // 如果没有登录 要去登录界面 放行
    if (to.path === '/login') {
      next()
      // 如果没有登录 要去忘记密码界面 放行
    } else if (to.path === '/resetpwd') {
      next()
    } else if (to.path === '/successreset') {
      // 如果没有登录 要去重置密码成功 放行
      next()
    } else if (to.path === '/register') {
      // 如果没有登录 要去注册页面 放行
      next()
    } else if (to.path === '/filladdress') {
      // 如果没有登录 要去注册地址 放行
      next()
    } else if (to.path === '/successregister') {
      // 如果没有登录 要去注册地址成功 放行
      next()
    } else if (to.path === '/') {
      // 如果没有登录 要去首页 放行
      next()
    } else if (to.path === '/homepage') {
      // 如果没有登录 要去首页 放行
      next()
    } else if (to.path === '/about/aboutus') {
      // 如果没有登录 要去首页 放行
      next()
    } else if (to.path === '/about/warehousing') {
      // 如果没有登录 要去首页 放行
      next()
    } else if (to.path === '/about/vehicles') {
      // 如果没有登录 要去首页 放行
      next()
    } else if (to.path === '/about/servicearea') {
      next()
    } else if (to.path === '/contact') {
      // 如果没有登录 要去首页 放行
      next()
    } else if (to.path === '/support/buyerfaq') {
      // 如果没有登录 要去首页 放行
      next()
    } else if (to.path === '/support/retailerfaq') {
      // 如果没有登录 要去首页 放行
      next()
    } else if (to.path === '/resources/privacypolicy') {
      next()
    } else if (to.path === '/resources/termsconditions') {
      next()
    } else if (to.path === '/resources/feessurcharge') {
      next()
    } else if (to.path === '/resources/tailoredsolutions') {
      next()
    } else if (to.path === '/resources/flexibleoptions') {
      next()
    } else if (to.path === '/resources/deliverysolutions') {
      next()
    } else if (to.path === '/resources/applicationsolution') {
      next()
    } else if (to.path === '/resources/orderassignment') {
      next()
    } else if (to.path === '/resources/sustainableoptions') {
      next()
    } else if (to.path === '/knowledge/blog') {
      next()
    } else if (to.path === '/knowledge/blogdetail') {
      next()
    } else if (to.path === '/knowledge/classify') {
      next()
    } else if (to.path === '/driver/qualifications') {
      next()
    } else if (to.path === '/driver/driverfaq') {
      next()
    } else {
      // 如果没有登录 要去其他界面就先登录
      next('/login')
    }
  }
  window.scrollTo(0, 0)
})

export default router
